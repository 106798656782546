export const timeSince = (timestamp: number) => {
  const now: any = new Date();
  const past: any = new Date(timestamp * 1000);

  const secondsPast = (now - past) / 1000;

  if (secondsPast < 60) {
    return `${Math.floor(secondsPast)} seconds ago`;
  }
  if (secondsPast < 3600) {
    return `${Math.floor(secondsPast / 60)} minutes ago`;
  }
  if (secondsPast <= 86400) {
    return `${Math.floor(secondsPast / 3600)} hours ago`;
  }
  if (secondsPast > 86400) {
    const day = past.getDate();
    const month = past
      .toDateString()
      .match(/ [a-zA-Z]*/)[0]
      .replace(" ", "");
    const year =
      past.getFullYear() === now.getFullYear() ? "" : ` ${past.getFullYear()}`;
    return `${day} ${month}${year}`;
  }
};
