const urls: Record<string, string> = {
  development: "http://localhost:9000",
  staging: "https://staging.verusids.com",
  production: "https://www.verusids.com",
};

const fetchBaseUrl = () => {
  console.log(import.meta.env.VITE_SOLID_APP_ENVIRONMENT);
  return urls[import.meta.env.VITE_SOLID_APP_ENVIRONMENT as string];
};

export default fetchBaseUrl;
