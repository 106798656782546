import server$ from "solid-start/server";
import { fetchPool } from "~/utils/fetchPool";

export const fetchIDData = server$(async () => {
  const pool = await fetchPool();
  const client = await pool.connect();
  const newids = await client.query("SELECT * FROM newids");
  client.release();
  return newids.rows;
});
