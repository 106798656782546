import server$ from "solid-start/server";
import { fetchPool } from "~/utils/fetchPool";

export const getUpdateTime = server$(async () => {
  const pool = await fetchPool();
  const client = await pool.connect();
  const updateTime = await client.query("SELECT * FROM updatetimes");
  console.log(updateTime.rows);
  client.release();
  return updateTime.rows;
});
