import { JSX } from "solid-js"
import PageHero from "~/components/PageHero/PageHero";
import PageFooter from "~/components/PageFooter/PageFooter";

interface LayoutProps {
    children: JSX.Element;
    listings?: any;
    onlyNav?: boolean;
}

export function Layout(props: LayoutProps) {
    return (
        <div class="relative h-full w-full">
            <PageHero onlyNav={props.onlyNav} />
            <div class="h-fit w-full min-h-[1200px] relative">
                {props.children}
            </div>
            <PageFooter />
        </div>
    )
}