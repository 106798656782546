interface PageFooterProps {

}


export const PageFooter = (props: PageFooterProps) => {

    return (
        <div class="relative w-full h-[300px] pagefooter-background bottom-0">
            <div class="absolute h-fit w-full lg:w-[80%]  bottom-14 flex justify-between items-end px-12 lg:px-0 lg:left-[10rem]">
                <div>
                    {/* <div class="flex flex-col space-y-4">
                        <div class="text-white text-4xl font-extralight">
                            Verus Links:
                        </div>
                        <div class="text-white">
                            (This is not the official virus.io Website, check links twice)
                        </div>
                    </div> */}

                    <div class="absolute h-[100px] w-[100px] top-12 lg:top-5 left-5 lg:-left-10 pointer-events-none">
                      <img src='/blue_accent.png' class="object-cover" />
                    </div>
                    <div class="flex flex-col flex-wrap items-start justify-start text-left lg:flex-row gap-y-2.5 lg:gap-y-0 lg:gap-x-10 mt-20 whitespace-nowrap">
                        <div class="text-white lg:text-2xl font-extralight">
                            <a href="https://verus.io/" target="_blank">
                                Verus.io
                            </a>
                        </div>
                        <div class="text-white lg:text-2xl font-extralight">
                            <a href="https://discord.gg/veruscoin" target="_blank">
                                Verus Discord
                            </a>
                        </div>
                        <div class="text-white lg:text-2xl font-extralight">
                            <a href="https://github.com/VerusCoin" target="_blank">
                                Github
                            </a>
                        </div>
                        <div class="text-white lg:text-2xl font-extralight">
                            <a href="https://wiki.verus.io/#!faq-cli/clifaq-02_verus_commands.md" target="_blank">
                                Verus Wiki
                            </a>

                        </div>
                        <div class="text-white text-left lg:text-2xl font-extralight">
                            <a href="https://verus.io/papers/VerusVision.pdf" target="_blank">
                                Vision Paper
                            </a>
                        </div>
                        <div class="text-white lg:text-2xl font-extralight">
                            <a href="https://explorer.verus.io/" target="_blank">
                                Block Explorer
                            </a>
                        </div>
                    </div>
                </div>
                <div class="h-fit">
                    <img height="50" width="50" src='/vrsc_logo_white.png' />
                </div>

            </div>
        </div>
    )
}
export default PageFooter
