import Nav from "../Nav/Nav";
import { For, createEffect, createSignal } from "solid-js";
import { JSX } from "solid-js";
import { fetchIDData } from "./PageHero.server";
import { useLocation } from "solid-start";
import { useParams } from "solid-start";
import fetchBaseUrl from "~/utils/fetchBaseUrl";
interface PageHeroProps {
  onlyNav?: boolean;
}

const backgrounds: Record<string, string> = {
  "/": "bg-[url('/hero-image-bg.webp')]",
  "/market": "bg-[url('/hero-image-market.png')]",
  "/eco": "bg-[url('/hero-image-eco.png')]",
  "/create": "bg-[url('/hero-image-web3.png')]",
};
export default function PageHero(props: PageHeroProps) {
  const location = useLocation();
  const params = useParams();
  const [searchTerm, setSearchTerm] = createSignal(
    params.id ? decodeURIComponent(params.id) : ""
  );

  const [newIDs, setNewIDs] = createSignal<Array<any>>([]);
  createEffect(() => {
    const load = async () => {
      const newids = await fetchIDData();
      setNewIDs(newids);
    };
    load();
  });

  const handleClick = (e: any) => {
    e.preventDefault();
    window.location = `${fetchBaseUrl()}/idlookup/${searchTerm()}` as any;
  };

  return (
    <div
      class={`w-full ${
        props.onlyNav
          ? "h-fit"
          : "h-[150px]"
     } relative ${
        backgrounds[location.pathname] || "bg-[url('/hero-image-bg.webp')]"
      } bg-auto bg-no-repeat bg-fixed`}
    >
      {!props.onlyNav && (
        <>
          <div class="absolute min-h-full h-fit w-[350px] z-10 web3container-background right-14 top-[50%] hidden lg:inline">
            <div class="flex flex-col justify-center items-center -space-y-7 mt-7">
              <div class="text-white text-[46px]">Welcome to</div>
              <div class="text-white text-[110px]">Web3</div>
            </div>
            <div class="flex flex-col p-8">
              <div class="text-white text-[40px]">ID Mints:</div>
              <For each={newIDs().reverse()}>
                {(id) => {
                  return <div class="text-white text-xl">{id.fullname}</div>;
                }}
              </For>
            </div>
          </div>
        </>
      )}
      <Nav listings={[]} />
    </div>
  );
}
