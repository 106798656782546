import { NavbarContainer, SiteTitle, SiteSubTitle } from "./Nav.style";
import { createSignal, createEffect, createMemo } from "solid-js";
import { useLocation } from "solid-start";
import { isServer } from "solid-js/web";
import { useRouteData } from "solid-start";
import { routeData } from "~/routes";
import { timeSince } from "~/utils/timeSince";
import { getUpdateTime } from "./Nav.server";
import fetchBaseUrl from "~/utils/fetchBaseUrl";
import { useParams } from "solid-start";

interface NavProps {
  listings: any[];
}

export default function Nav(props: NavProps) {
  const [heading, setHeading] = createSignal<string>();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = createSignal<boolean>(false);
  const [lastUpdated, setLastUpdated] = createSignal<string>();
  const location = useLocation();

  const params = useParams();
  const [searchTerm, setSearchTerm] = createSignal(
    params.id ? decodeURIComponent(params.id) : ""
  );

  createEffect(() => {
    switch (location.pathname) {
      case "/currencies":
        setHeading("Currencies Active Now");
        break;
      case "/register":
        setHeading("Register a SubID");
        break;
      default:
        setHeading("Verus IDS Listed Now");
        break;
    }
  });

  createEffect(() => {
    getUpdateTime().then((val) => {
      const { unixtime } = val[0];
      setLastUpdated(timeSince(unixtime));
    });
  });

  createEffect(() => {
    if (isServer) return;
    const body = document.getElementById("body");
    if (!body) return;
    if (isMobileMenuOpen()) {
      body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "auto";
    }
  });

  const handleClick = (e: any) => {
    e.preventDefault();
    window.location = `${fetchBaseUrl()}/idlookup/${searchTerm()}` as any;
  };

  return (
    <div class="w-full relative z-10">

      <div class="top-0 relative flex items-center justify-between nav-background w-fill h-[50px] px-10 lg:pl-36 lg:pr-10">
        <div class="flex items-center justify-center gap-x-2">
          <div class="text-white text-[24px]">
            <a href="/">VerusIDs.com</a>
          </div>
        </div>

        <div class="text-white text-[40px] font-light flex items-center space-x-2 w-full">
          <form onSubmit={handleClick} class="flex w-full">
            <input
              id="search-ref"
              class="input h-10 w-fit hidden sm:inline sm:w-[300px] h-fit background-transparent px-[10px] rounded outline outline-1 outline-black text-black text-lg lg:min-w-[400px]"
              value={(searchTerm() as string) || ""}
              onChange={(e) => setSearchTerm(e.target.value)}
              max={100}
              placeholder="Search for a VerusID"
            />
          </form>
        </div>
        <div class="flex items-center justify-center space-x-4">
          <div class="text-xs hidden lg:inline  whitespace-nowrap text-white">
            Last Updated: {lastUpdated()}
          </div>
          <div class="flex-col hidden lg:flex lg:flex-row items-center justify-center h-full space-y-20 lg:space-y-0">
            <a
              href="/market"
              class={`text-xl whitespace-nowrap ${location.pathname === "/market"
                ? "text-link-color"
                : "text-white"
                }`}
            >
              ID Market
            </a>
            <a
              href="/register"
              class={`text-xl  whitespace-nowrap ${location.pathname === "/register"
                ? "text-link-color"
                : "text-white"
                }`}
            >
              Register
            </a>
          </div>
        </div>
        <div
          onClick={() => {
            setIsMobileMenuOpen(!isMobileMenuOpen());
          }}
          class="lg:hidden inline hover:cursor-pointer rounded-sm relative z-[100] padding-1 transition-all ease-in-out duration-[200ms]"
        >
          <button>
            <img height="50" width="50" src="/hamburger_menu.svg" />
          </button>
        </div>
      </div>
      <div class="flex items-center justify-center md:hidden w-full bg-[rgba(0,0,0,0.5)] px-3 py-4">
        <div class="text-white text-[40px] font-light flex items-center justify-center space-x-2 w-full">
          <form onSubmit={handleClick} class="flex items-center justify-center w-full">
            <input
              id="search-ref"
              class="input-light h-10 w-fit inline sm:w-[300px] h-fit bg-[rgba(255,255,255,0.5)] px-[10px] rounded outline outline-1 outline-white text-black text-lg lg:min-w-[400px]"
              value={(searchTerm() as string) || ""}
              onChange={(e) => setSearchTerm(e.target.value)}
              max={100}
              placeholder="Search for a VerusID"
            />
          </form>
        </div>
      </div>

      <div
        class={`fixed top-[50px] flex flex-col  h-full z-[200] overflow-hidden ${isMobileMenuOpen() ? "left-0 w-full" : "left-[100%] w-0"
          } transition-all ease-in-out duration-200 `}
      >
        <div class="absolute h-full w-full top-0 left-0 pagefooter-background opacity-[0.98] overflow-hidden" />
        <div class="h-full relative flex flex-col">
          <div class="relative h-fit">
            <div class="mt-10 flex flex-col items-center justify-center space-y-10">
              <a
                href="/market"
                class={`text-xl ${location.pathname === "/market"
                  ? "text-link-color"
                  : "text-white"
                  }`}
              >
                ID Market
              </a>
              <a
                href="/register"
                class={`text-xl ${location.pathname === "/register"
                  ? "text-link-color"
                  : "text-white"
                  }`}
              >
                Register
              </a>
            </div>
          </div>

          <div class="relative h-full !mt-72 mx-auto">
            <SiteSubTitle class="!text-[1rem]">
              Made with 💙 by the Verus Community
            </SiteSubTitle>
          </div>
        </div>
      </div>
    </div>
  );
}
